import * as React from 'react';

import './PageTitle.scss';

interface Props {
  title: string;
  className?: string;
}

export const PageTitle = ({ title, className }: Props) => (
  <div
    className={['page-title', className].join(' ')}
    role="heading"
  >
    {title}
  </div>
);
