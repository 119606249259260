import * as React from 'react';
import { SEO } from 'src/components/SEO';
import { Layout } from 'src/components/Layout';
import { PageTitle } from 'src/components/PageTitle';

const PrivacyPage = () => (
  <Layout className="privacy">
    <SEO title="Contact" />
    <div className="container pt-2 pb-4">
      <PageTitle className="mb-2 mb-lg-8" title='Privacy policy' />
      <h2>Why are we presenting you with these rules on the processing of personal data?</h2>
      <p>
        We have drafted these rules on the processing of personal data to clarify what we do to
        ensure that your personal data remain safe and confidential. The aim of this document is
        to provide you with information about the personal data we collect, why we collect it, how
        we use it, what sources we receive it from, what the purpose of collecting is, who we are
        permitted to provide the data to, and where you can get additional information about which
        of your personal data is processed and how it is secured.
      </p>
      <p>
        Please note that if you do not agree with the processing of your personal data or privacy
        policy, you cannot use our services.
      </p>
      <p>
        If you have already used our services in the past, you are probably aware that we offer
        employment agency-related services personally or through our website www.alviso.cz, as well
        as through other ways, including partner websites or social media. This information sheet on
        the processing of personal data applies to any and all personal data which we collect through
        these platforms or by other means linked to these platforms.
      </p>
      <p>Alviso Recruitment as the controller of your personal data</p>
      <p>
        Alviso Recruitment s.r.o., Company ID No.: 03614379, with its registered office at Janáčkovo nábřeží 133/61,
        Malá Strana, 150 00 Praha 5, is the controller of your personal data. The fact that we are
        a controller of personal data means that we respect the principles of personal data processing
        and treat your personal data solely in accordance with applicable laws, especially the Regulation
        (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 (General Data
        Protection Regulation), decide on the purpose and means of the processing your personal data,
        and ensure that your personal data is properly secured. We protect your personal data using
        state-of-the-art technology and we regularly evaluate and update our processes behind securing
        your personal data.
      </p>
      <h2>Why do we process your personal data?</h2>
      <p>
        Whether you are looking for a job, replying to a specific listing, creating a profile on our
        website, providing us with your CV (directly or upon our recruitment or head hunting process),
        or contacting us for job information in other ways (electronically, in writing, on our recruitment
        form, by telephone, etc.), you are providing us with your personal data, which we process
        throughout the entire process of job placement and when providing our services. All of our
        services relate to job searching, the recruitment process, head hunting and personal development.
      </p>
      <p>
        We only process your personal data for legitimate reasons. Unless otherwise specified, we process
        the personal data of job applicants only with their express consent, or in connection with the
        performance of the contract concluded with us, as applicable. If we retain your personal data,
        e.g. due to legal dispute, such processing is based on our legitimate interest. Any transfer of
        candidates‘ personal data to our contractual partners is based on your consent.
      </p>
      <h2>What personal data do we process?</h2>
      <p>We may process the following personal data or categories of personal data. This does not mean that
        we process all the personal data below for each candidate. For example, we are unable to fully list
        the personal data that job applicants submit to us in their CVs and other related documents. Please
        note that examples may not be exhaustive.
      </p>
      <p>
        Name, last name<br />
        Academic title, education achieved<br />
        Permanent address, contact address<br />
        Country of citizenship<br />
        Date and place of birth, birth number<br />
        Native language<br />
        Phone number, email address<br />
        Expected remuneration / salary<br />
        Driver’s license<br />
        Information listed on your resume, your profile on our website, the attachments to your reply to the listing or your request for job information<br />
        Work experience / information about previous or current employment<br />
        Information about registration with the Labour Office<br />
        Photograph (if you provide it to us as an attachment to your CV)<br />
        Link to your profile on the social networks and their content<br />
        Information on commencing employment, amount of salary, termination of employment<br />
        Language skills, special skills/knowledge and professional qualifications<br />
        Assessment of personality characteristics<br />
        What purposes do we process your data for?<br />
      </p>
      <p>
        Processing your personal data enables us to facilitate activity as an employment agent and provide
        related services, which include, but are not limited to, the response to your employment application
        and correspondence between you and your potential employers during the recruitment process. We further
        process personal data for direct marketing and statistical purposes, such processing is reasoned by our
        legitimate interest.
      </p>
      <p>
        Personal data is collected to facilitate an effective recruitment process for applicants and clients
        when assessing your skills, experience, qualifications, and suitability for both existing and future
        jobs that may be of interest to you. In particular, we assess whether you are suitable for the job as
        a candidate and that your education, experience and language skills meet the requirements of the listing,
        and whether the remuneration is in line with your expectations. This process of assessing may be categorize
        as profiling according to article 4 paragraph 4 of the General Data Protection Regulation. By accepting
        our Privacy Policy you agree with profiling of your personal data for such purpose.
      </p>
      <p>
        Personal data are also collected and stored by us to be processed for the purpose of Alviso as a
        job-agency. Our website allows you to submit attachments with your application to a specific position.
        You submit these attachments on a voluntary basis, and the personal data contained in the attachments
        will be processed only for the purposes of assessing your suitability, as the candidate, for the
        position.
      </p>
      <p>
        Your profile on our website allows you to link your job application with your social networking
        accounts (such as LinkedIn, Facebook). If you choose to provide a link to your social network account,
        we may process not only this link but also the data on your accounts, but only in connection with
        our activity as an employment agent.
      </p>
      <h2>Where do we get your personal data from?</h2>
      <p>
        We receive the majority of personal data that we process directly from you (e.g. through our website,
        by replying to position listing, fairs or other events, giving us your business cards etc.). Sometimes
        we also receive your personal data from third parties, e.g. from job applicant portals or from your
        previous employers, in cases when you have provided us with their contact information, and from our
        clients in case of employment or other similar contract have been entered into.
      </p>
      <h2>How long do we store your data for?</h2>
      <p>
        We only process personal data for the period of time that is necessary for the purpose for which
        we are processing it. Assuming you do not delete your profile or withdraw your consent given by
        registration, we process your personal data for 7 years since last entering into the contract with
        us or our client, but not less than 7 years since the consent was granted. When we find that your
        data is no longer necessary for any of the purposes for which it was processed, we discard it.
      </p>
      <h2>Who is your personal data transferred to?</h2>
      <p>
        Due to the fact that we process your personal data specifically for the purpose of our activities
        as an employment agent and to provide related services, we transfer your data to employers looking
        for employees to fill position listings or listings created by Alviso. In such situation, Alviso
        and the potential employer are independent controllers of your personal data.
      </p>
      <p>
        Personal data processed as per this information sheet may further be provided to authorized state
        authorities, institutions or other entities when the conditions specified by legal regulation are
        met. Should it be necessary to fulfill the purpose of processing personal data necessary for the
        performance of our legal obligations or our contractual obligations, should this be necessary to
        protect our legitimate interests, or should it follow from your consent, we may transfer your
        personal data to external suppliers, who support us during the recruitment process. Those external
        suppliers may be in position of processors of your personal data, in such case the suppliers are
        required to process and secure the personal data in compliance with the General Data Protection
        Regulation.
      </p>
      <h2>What are your rights?</h2>
      <p>
        You have the right to request information about your personal data and access it. You further have
        the right to ask us to correct or delete your personal data or to limit its processing. You may also
        object to the processing of your personal data or request the portability of the data. However, please
        note that we do not always have to comply with your cancellation, limitation, objection or portability
        request. Case-by-case reviews of our legal obligations and exemptions from these rights will be made.
      </p>
      <p>
        You also have the right to address your claim, complaint, or other query directly to the Personal
        Data Protection Office, with its seat at Pplk. Sochora 27, 170 00 Prague 7, phone: +420 234 665 111
        (operator), e-mail: posta@uoou.cz.
      </p>
      <p>Contact details of the Data Protection Officer</p>
      <p>
        Should you have any questions regarding the processing of your personal data, please contact our
        data protection officer, email: info@alviso.cz
      </p>
      <p>Marketing communications</p>
      <p>
        We may also use your personal data, among other purposes, to send you commercial messages regarding
        our employment agency services, both electronically and via SMS. We may use this data for these
        communications unless you (the addressee) have declined this option, such processing is reasoned
        by our legitimate interest.
      </p>
      <p>
        You have the right to decline receiving commercial messages at any time. You may do so via email,
        info@alviso.cz, through your profile, by following the link in a commercial message, or by sending
        a message to any of the contacts listed in these rules for the processing of personal data. You can
        do so also by filling out our contact questionnaire on Alviso website.
      </p>
      <p>
        Not every message that we send you is a commercial message. We also use your contact information to
        communicate with you, e.g. when sending out information on changes to the General Terms and Conditions
        or these rules for the processing of personal data.
      </p>
      <p>
        We may also use your personal data for remarketing and targeting advertises on our websites as well
        as websites of third parties and social network (e.g. Facebook, Linkedin).
      </p>
    </div>
  </Layout>
);

export default PrivacyPage;
